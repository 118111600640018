import axios from "axios";
import config from "../config";

export const getDocument = ({ documentId }: { documentId: string }) => axios.get(`${config.API_URL}/documents/${documentId}`)

export const createDocument = (
    {
        token,
        title,
        description,
        file_id,
        subject_id,
        document_category_id
    }: {
        token: string
        title: string
        description: string
        file_id: string
        subject_id: string
        document_category_id: string
    }) => axios.post(`${config.API_URL}/documents`, {
        title,
        description,
        file_id,
        subject_id,
        document_category_id
    }, {
        headers: {
            Authorization: `Bearer ${token}`
        }
    })

export const searchDocuments = (
    {
        query,
        page,
        limit,
        institutionId,
        subjectId,
        extensions,
        sortOrder,
        sortRef
    }: {
        query: string
        page: number
        limit: number
        institutionId?: string | null
        subjectId?: string | null
        extensions?: string | null
        sortRef: string
        sortOrder: string
    }
) => axios.post(`${config.API_URL}/documents/search?limit=${limit}&page=${page}&institutionId=${institutionId}&subjectId=${subjectId}&extensions=${extensions}&order=${sortOrder}`, { query })