interface UnitInterface {
    name: string
    abbreviation: string
    bytes: number
}

export const BYTES_REF = 1024

const units: UnitInterface[] = [
    {
        name: "Bytes",
        abbreviation: "b",
        bytes: 1
    },
    {
        name: "Kilobytes",
        abbreviation: 'kb',
        bytes: BYTES_REF
    },
    {
        name: "Megabytes",
        abbreviation: "mb",
        bytes: BYTES_REF * BYTES_REF
    },
    {
        name: "Gigabytes",
        abbreviation: "gb",
        bytes: BYTES_REF * BYTES_REF * BYTES_REF
    },
    {
        name: "Terabytes",
        abbreviation: "tb",
        bytes: BYTES_REF * BYTES_REF * BYTES_REF * BYTES_REF
    }
]

export const getSizeUnit = (size: number): UnitInterface =>
    units.find((unit) => size / unit.bytes > 0 && size / unit.bytes < BYTES_REF) || units[0]

export const sizeParse = (size: number): string => {
    const unit = getSizeUnit(size)
    const sizeParsed = size / unit.bytes

    return `${sizeParsed.toFixed(2)} ${unit.abbreviation.toLocaleUpperCase()}`
}