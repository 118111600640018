import { createSlice } from '@reduxjs/toolkit'

export interface themesSliceInterface {
    dark?: boolean
    light?: boolean
    automatic?: boolean
}

const initialState: themesSliceInterface = {
    dark: false,
    light: false,
    automatic: false
}

export const themesSlice = createSlice({
    name: 'themes',
    initialState,
    reducers: {
        changeTheme: (_, { payload: theme }) => {
            let newState = { ...initialState, [theme]: true }
            return newState
        },
        setDefaultTheme: (state) => {
            state.automatic = true
        },
        restartTheme: () => initialState
    }
})
export const { changeTheme, restartTheme, setDefaultTheme } = themesSlice.actions
export default themesSlice.reducer