import { Navigate, createBrowserRouter, useLocation } from "react-router-dom"
import { useAppSelector } from "../store/store"
import React from "react"
import Home from "../pages/Home"
import Template from "../pages/Template"
import SearchPage from "../pages/Search"
import UploadPage from "../pages/Upload"
import FileDetails from "../pages/FileDetails"
import DocumentPage from "../pages/Document"
import InstitutionTemplate from "../pages/InstitutionTemplate"
import InstitutionMainPage from "../pages/InstitutionTemplate/Main"
import InstitutionSubjectPage from "../pages/InstitutionTemplate/Subject"
import TermsAndConditionsPage from "../pages/TemsAndCoditions"
import PrivacyPoliciesPage from "../pages/PrivacyPolicies"
import ProfilePage from "../pages/Profile"
import MyLibraryPage from "../pages/MyLibrary"

interface ComponentWithChildren {
    children: JSX.Element
}

const AuthRequired: React.FC<ComponentWithChildren> = ({ children }) => {
    const token = useAppSelector(state => state.token)
    const location = useLocation()

    if (!token) {
        return <Navigate to="/" state={{ from: location }} replace />
    }
    return children
}

const AuthNoRequired: React.FC<ComponentWithChildren> = ({ children }) => {
    const token = useAppSelector(state => state.token)
    const location = useLocation()

    if (token) {
        return <Navigate to="/" state={{ from: location }} replace />
    }
    return children
}

const router = createBrowserRouter([
    {
        path: "/",
        element: <Template />,
        children: [
            {
                path: "/",
                element: <Home />
            },
            {
                path: "/search",
                element: <SearchPage />
            },
            {
                path: "/upload",
                element:
                    <AuthRequired>
                        <UploadPage />
                    </AuthRequired>
            },
            {
                path: "/files/:fileId/details",
                element:
                    <AuthRequired>
                        <FileDetails />
                    </AuthRequired>
            },
            {
                path: "/documents/:documentId",
                element: <DocumentPage />
            },
            {
                path: "/institutions/:institutionId",
                element: <InstitutionTemplate />,
                children: [
                    {
                        path: "",
                        element: <InstitutionMainPage />
                    },
                    {
                        path: ":subjectId",
                        element: <InstitutionSubjectPage />
                    }
                ]
            },
            {
                path: "/terms-and-conditions",
                element: <TermsAndConditionsPage />
            },
            {
                path: "/privacy-policies",
                element: <PrivacyPoliciesPage />
            },
            {
                path: "/profile",
                element:
                    <AuthRequired>
                        <ProfilePage />
                    </AuthRequired>
            },
            {
                path: "/my-library",
                element:
                    <AuthRequired>
                        <MyLibraryPage />
                    </AuthRequired>
            },
        ]
    }
])

export default router