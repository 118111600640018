import { useNavigate } from "react-router-dom";
import { useAppSelector } from "../../store/store";
import { useState } from "react";
import { Helmet } from "react-helmet-async";
import config from "../../config";
import Text from "../../components/Text";

function Home() {
    const [query, setQuery] = useState<string>("")
    const navigate = useNavigate()
    const themes = useAppSelector(state => state.themes)


    const search: React.FormEventHandler<HTMLFormElement> = (event) => {
        event?.preventDefault()
        if (query.trim().length > 0) {
            navigate(`/search/?query=${query}&type=documents`, { replace: true })
        }
    }

    return (
        <>
            <Helmet>
                <title>{config.pageName}</title>
                <meta
                    name="description"
                    content={config.pageDescription}
                />
            </Helmet>
            <div className={
                    `
                ${themes.light && 'bg-white'} ${themes.dark && 'bg-zinc-800'}
                ${themes.automatic && 'bg-white dark:bg-zinc-800'}
                flex flex-auto justify-center px-2 py-5
                `
            }>
                <div className="flex flex-col gap-4 w-full max-w-[520px]">
                    <div className="flex flex-col">
                        <img className="w-[200px] self-center" src="https://decairo.com/student.png" alt="logo" />
                    </div>
                    <Text className="text-center">Siempre vamos a estar de tu lado ;)</Text>
                    <form className="flex flex-col gap-5" onSubmit={search}>
                        <input
                            className="text-zinc-700 h-[45px] border rounded-full px-5 outline-none hover:rounded focus:rounded focus:shadow-lg transition-all duration-100"
                            type="search"
                            placeholder="Buscar"
                            onChange={(event) => setQuery(event.target.value)}
                            value={query}
                        />
                        <div className="flex justify-center gap-5">
                            <button
                                className={`
                                    ${themes.light && 'bg-gray-100'} ${themes.dark && 'bg-zinc-900'}
                                    ${themes.automatic && 'bg-gray-100 dark:bg-zinc-900'}

                                    ${themes.light && 'text-zinc-700'} ${themes.dark && 'text-white'}
                                    ${themes.automatic && 'text-zinc-700 dark:text-white '}

                                    text-sm

                                    px-5 h-[40px] rounded active:scale-95 duration-75
                                `}
                            >
                                Buscar
                            </button>
                            <a href="#last_contributions"
                                className={`
                                    flex items-center justify-center
                                    ${themes.light && 'bg-gray-100'} ${themes.dark && 'bg-zinc-900'}
                                    ${themes.automatic && 'bg-gray-100 dark:bg-zinc-900'}

                                    ${themes.light && 'text-zinc-700'} ${themes.dark && 'text-white'}
                                    ${themes.automatic && 'text-zinc-700 dark:text-white'}
            
                                    text-sm
            
                                    px-5 h-[40px] rounded active:scale-95 duration-75
                                `}
                            >
                                Últimas contribuciones
                            </a>
                        </div>
                    </form>
                </div>
            </div>
        </>
    );
}

export default Home;
