import { Helmet } from "react-helmet-async"
import Body from "../../components/Body"
import Text from "../../components/Text"
import config from "../../config"

const TermsAndConditionsPage = () => {
    return (
        <>
            <Helmet>
                <title>Términos y Condiciones {config.titleSeparator}</title>
                <meta
                    name="description"
                    content={config.pageDescription}
                />
            </Helmet>
            <Body className="p-5 gap-5">
                <Text size="3xl">Términos y Condiciones de Uso</Text>

                <Text>
                    Bienvenido a nuestra plataforma de contenido colaborativo. Antes de comenzar a utilizar nuestros servicios, te pedimos que leas y aceptes los siguientes términos y condiciones. Al acceder o utilizar nuestro sitio web, estás aceptando cumplir con estos términos y condiciones. Si no estás de acuerdo con alguna parte de estos términos, por favor no utilices nuestro servicio.
                </Text>

                <Text className="font-semibold">
                    1. Uso Aceptable
                </Text>

                <Text>
                    1.1. Nuestra plataforma está destinada para el intercambio de contenido colaborativo entre usuarios. Al utilizar nuestros servicios, te comprometes a no realizar actividades que puedan infringir los derechos de otros usuarios, incluyendo, pero no limitado a, el envío de contenido ofensivo, difamatorio o ilegal.
                </Text>

                <Text>

                    1.2. Te comprometes a no utilizar nuestra plataforma con fines comerciales sin nuestro consentimiento previo por escrito.
                </Text>

                <Text className="font-semibold">
                    2. Propiedad Intelectual
                </Text>

                <Text>
                    2.1. El contenido compartido en nuestra plataforma sigue siendo propiedad de los usuarios que lo crearon. Sin embargo, al compartir contenido en nuestra plataforma, otorgas a nuestra empresa una licencia mundial, no exclusiva, transferible y sublicenciable para utilizar, reproducir, modificar, adaptar, publicar, traducir y distribuir dicho contenido con el fin de operar y promover nuestros servicios.
                </Text>

                <Text>
                    2.2. Respetamos los derechos de propiedad intelectual de terceros y esperamos que los usuarios hagan lo mismo. Si crees que tu propiedad intelectual ha sido infringida en nuestra plataforma, por favor contáctanos de inmediato.
                </Text>

                <Text className="font-semibold">
                    3. Responsabilidad del Usuario
                </Text>

                <Text>
                    3.1. Eres responsable del contenido que compartes en nuestra plataforma, así como de cualquier actividad que realices mientras estés utilizando nuestros servicios.
                </Text>

                <Text>
                    3.2. Te comprometes a no utilizar nuestra plataforma para realizar actividades que puedan dañar, sobrecargar o comprometer la seguridad de nuestros sistemas.
                </Text>

                <Text className="font-semibold">
                    4. Privacidad
                </Text>

                <Text>
                    4.1. Respetamos tu privacidad y nos comprometemos a proteger tus datos personales de acuerdo con nuestra política de privacidad. Al utilizar nuestros servicios, aceptas nuestra política de privacidad.
                </Text>

                <Text className="font-semibold">
                    5. Modificaciones a los Términos y Condiciones
                </Text>

                <Text>
                    5.1. Nos reservamos el derecho de modificar estos términos y condiciones en cualquier momento. Cualquier cambio será efectivo inmediatamente después de su publicación en esta página. Te recomendamos que revises periódicamente estos términos y condiciones para estar al tanto de cualquier cambio.
                </Text>

                <Text className="font-semibold">
                    6. Ley Aplicable
                </Text>

                <Text>
                    6.1. Estos términos y condiciones se regirán e interpretarán de acuerdo con las leyes de Argentina sin tener en cuenta sus disposiciones sobre conflictos de leyes.
                </Text>

                <Text className="font-semibold">
                    7. Contacto
                </Text>

                <Text>
                    7.1. Si tienes alguna pregunta o inquietud sobre estos términos y condiciones, por favor contáctanos a <a className="text-blue-500 hover:underline" href="mailto:contact@decairo.com">contact@decairo.com</a>.
                </Text>

                <Text size="lg" className="font-bold">
                    Al utilizar nuestros servicios, aceptas cumplir con estos términos y condiciones. Si no estás de acuerdo con alguno de los términos establecidos aquí, por favor no utilices nuestra plataforma.
                </Text>

                <Text>
                    Gracias por tu cooperación.
                </Text>

                <Text>
                    Última actualización: 22/04/2024
                </Text>
            </Body>
        </>
    )
}

export default TermsAndConditionsPage