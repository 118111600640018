import axios from "axios";
import config from "../config";

export const getFileData = ({ token, fileId }: { token: string, fileId: string }) => axios.get(`${config.API_URL}/files/${fileId}`, {
    headers: {
        Authorization: `Bearer ${token}`
    }
})

export const getPendingFiles = ({ token }: { token: string }) => axios.get(`${config.API_URL}/files/pending`, {
    headers: {
        Authorization: `Bearer ${token}`
    }
})

export const deleteFile = ({ token, fileId }: { token: string, fileId: string }) => axios.delete(`${config.API_URL}/files/${fileId}`, {
    headers: {
        Authorization: `Bearer ${token}`
    }
})