import { useEffect, useState } from "react"
import ReactQuill from "react-quill";
import 'react-quill/dist/quill.snow.css';

const TakeNotes = () => {
    const [text, setText] = useState<string>("")

    useEffect(() => {
        console.log(text)
    }, [text])

    return (
        <div className="flex flex-col gap-2">
            <ReactQuill value={text} onChange={setText} />
        </div>
    )
}

export default TakeNotes