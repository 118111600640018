import { Helmet } from "react-helmet-async"
import InputText from "../../../components/InputText"
import Subject from "../../../components/Subject"
import Text from "../../../components/Text"
import { useAppSelector } from "../../../store/store"
import config from "../../../config"

const InstitutionMainPage = () => {
    const institution = useAppSelector(state => state.institution)
    return (
        <>
            <Helmet>
                <title>{institution.name} {config.titleSeparator}</title>
                <meta
                    name="description"
                    content={institution.website}
                />
            </Helmet>
            <div className="flex flex-col gap-5">
                <Text size="3xl">Asignaturas</Text>
                {/* <InputText placeholder="Buscar asignaturas" /> */}
                <div className="grid grid-cols-1 md:grid-cols-2 gap-5">
                    {
                        institution?.subjects?.map((subject) => (
                            <Subject key={subject.id} {...subject} />
                        ))
                    }
                </div>
            </div>
        </>
    )
}

export default InstitutionMainPage