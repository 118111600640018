import { useEffect, useState } from "react"
import { Link, useParams } from "react-router-dom"
import { getSubjectDocuments } from "../../../services/subject.services"
import SubjectInterface from "../../../interfaces/subject.interfaces"
import { AxiosResponse } from "axios"
import Text from "../../../components/Text"
import Document from "../../../components/Document"
import { RiArrowLeftSLine } from "react-icons/ri"
import Loader from "../../../components/Loader"
import Button from "../../../components/Button"
import { Helmet } from "react-helmet-async"
import config from "../../../config"

const InstitutionSubjectPage = () => {
    const { subjectId } = useParams()
    const [isLoading, setIsLoading] = useState<boolean>(true)
    const [subject, setSubject] = useState<SubjectInterface>()
    const [paginationData] = useState<{ total: number, pages: number, currentPage: number }>({
        total: 0,
        pages: 0,
        currentPage: 0,
    })
    const [sortData] = useState<{ sortRef: string, sortOrder: string }>({
        sortRef: 'date',
        sortOrder: 'desc'
    })

    useEffect(() => {
        getSubjectDocuments({
            subjectId: subjectId as string,
            limit: 10,
            page: paginationData.currentPage,
            sortRef: sortData.sortRef,
            sortOrder: sortData.sortOrder
        })
            .then((res: AxiosResponse) => {
                setSubject(res.data.payload.subject)
                console.log(res.data.payload.subject)
                setIsLoading(false)
            })
    }, [])

    if (isLoading) return (
        <>
            <Helmet>
                <title>Cargando... {config.titleSeparator}</title>
                <meta
                    name="description"
                    content={config.pageDescription}
                />
            </Helmet>
            <Loader />
        </>
    )

    return (
        <>
            <Helmet>
                <title>{subject?.name} {config.titleSeparator}</title>
                <meta
                    name="description"
                    content={subject?.institution?.website}
                />
            </Helmet>
            <div className="flex flex-col pb-5 gap-2">
                <Link to={`/institutions/${subject?.institution?.url_name || subject?.institution?.id}`}>
                    <Text size="3xl" flexed><RiArrowLeftSLine /> {subject?.name}</Text>
                </Link>

                <div className="flex flex-col gap-5">
                    {(subject?.documents?.length || 0) > 0 ?
                        subject?.documents?.map((document) => <Document key={document.id} {...document} />)
                        : <Text>Aún no se publicaron documentos en esa asignatura.</Text>}
                    {paginationData.currentPage < paginationData.pages &&
                        <Button>Cargar más</Button>
                    }
                </div>
            </div>
        </>
    )
}

export default InstitutionSubjectPage