import { useState } from "react"
import { RiLogoutBoxLine, RiUser3Fill } from "react-icons/ri";
import { useAppDispatch, useAppSelector } from "../../store/store"
import Modal from "../Modal"
import SignInButton from "../SignInButton"
import { deleteToken } from "../../store/features/tokenSlice";
import { Link } from "react-router-dom";
import { BiLibrary } from "react-icons/bi"
import Text from "../Text";
import { FaUniversity } from "react-icons/fa";

const Navigation = () => {
    const themes = useAppSelector(state => state.themes)
    const token = useAppSelector(state => state.token)
    const uploadQueue = useAppSelector(state => state.uploadQueue)
    const user = useAppSelector(state => state.user)
    const [menu, setMenu] = useState<boolean>(false)
    const dispatch = useAppDispatch()

    return (
        <nav className={`
            flex items-center justify-between
            sticky top-0
            ${themes.light && 'bg-white'} ${themes.dark && 'bg-zinc-800'}
            ${themes.automatic && 'bg-white dark:bg-zinc-800'}
            min-h-[70px]
            px-4 md:px-10
        `}
        >
            <div className="flex flex-col">
                <Link to="/" className="text-blue-500 text-lg uppercase">Decairo</Link>
            </div>
            <div className="flex items-center gap-2">
                {token ?
                    <>
                        <Link to="/upload">
                            <Text>{uploadQueue.length ? "Finalizar subidas" : "Subir documento"}</Text>
                        </Link>
                        <button
                            className="flex gap-2 items-center p-2 rounded-full border border-transparent hover:border-zinc-500 hover:shadow-sm duration-100"
                            onClick={() => setMenu(true)}
                        >
                            {/* <p className="text-white capitalize ">{user.name}</p> */}
                            {user.profile_picture && <img className="min-w-[30px] min-h-[30px] max-w-[30px] max-h-[30px] rounded-full" src={user.profile_picture} alt={user.email} />}
                        </button>
                        <Modal set={setMenu} modalOpen={menu}>
                            <div className={`
                                py-5
                                md:self-end md:fixed md:top-0 md:mr-10
                                h-full
                            `}>
                                <div className={`flex flex-col gap-5 w-full p-5 max-w-[360px] bg-gray-200 rounded-3xl max-h-full overflow-auto `}>

                                    <div className="flex flex-col gap-2 pt-5 px-5 justify-center items-center">
                                        <img className="w-[80px] h-[80px] rounded-full" src={user.profile_picture} alt={user.email} />
                                        <p className="text-zinc-700 text-xl">{user.name}</p>
                                        <p className="text-zinc-700">{user.email}</p>
                                    </div>
                                    <div className="flex flex-col gap-2">
                                        <p className="text-zinc-700 text-sm px-5">Área personal</p>
                                        <div className="flex flex-col gap-1">
                                            <Link to="/profile" className="flex items-center gap-2 h-[50px] first:rounded-t-3xl last:rounded-b-3xl bg-white text-zinc-700 text-center px-5">
                                                <RiUser3Fill /> Mí perfil
                                            </Link>
                                            {/* <button className="flex items-center gap-2 h-[50px] first:rounded-t-3xl last:rounded-b-3xl bg-white text-zinc-700 text-center px-5">
                                            <RiHistoryFill /> Historial
                                        </button> */}
                                            <Link to="/my-library" className="flex items-center gap-2 h-[50px] first:rounded-t-3xl last:rounded-b-3xl bg-white text-zinc-700 text-center px-5">
                                                <BiLibrary /> Mi biblioteca
                                            </Link>
                                        </div>
                                    </div>
                                    {user.institution &&
                                        <div className="flex flex-col gap-2">
                                            <p className="text-zinc-700 text-sm px-5">Intitución</p>
                                            <div className="flex flex-col gap-1">
                                                <Link to={`/institutions/${user.institution.id}`} className="flex items-center gap-2 h-[50px] first:rounded-t-3xl last:rounded-b-3xl bg-white text-zinc-700 text-center px-5">
                                                    <FaUniversity /> Universidad
                                                </Link>
                                            </div>
                                        </div>
                                    }

                                    <div className="flex flex-col gap-2">
                                        <div className="flex flex-col gap-1">
                                            <button
                                                className="flex items-center gap-2 h-[50px] first:rounded-t-3xl last:rounded-b-3xl bg-white text-red-700 text-center px-5"
                                                onClick={() => {
                                                    dispatch(deleteToken())
                                                    setMenu(false)
                                                }}
                                            >
                                                <RiLogoutBoxLine /> Salir
                                            </button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </Modal>
                    </>
                    :
                    <SignInButton />
                }
            </div>

        </nav >
    )
}

export default Navigation