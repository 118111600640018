import { CredentialResponse, GoogleLogin } from "@react-oauth/google"
import axios, { AxiosError, AxiosResponse } from "axios"
import { toast } from "react-toastify"
import config from "../../config"
import { setToken } from "../../store/features/tokenSlice"
import { useAppDispatch } from "../../store/store"

const SignInButton = () => {
    const dispatch = useAppDispatch()

    const onGoogleAuthSuccess = (payload: CredentialResponse): void => {
        axios.post(`${config.API_URL}/auth/google`, {
            client_id: config.OAuthId,
            credential: payload.credential
        }, {
            headers: {
                "Content-Type": "application/json"
            }
        })
            .then((res: AxiosResponse) => {
                toast.success(res.data.message)
                dispatch(setToken(res.data.payload.token))
            })
            .catch((error: AxiosError) => {
            })
    }

    return (
        <GoogleLogin
            onSuccess={onGoogleAuthSuccess}
            onError={() => {
                console.log('Login Failed');
            }}
        />
    )
}

export default SignInButton