import { useEffect, useState } from "react"
import { getAllInstitutions } from "../../services/institution.services"
import { AxiosResponse } from "axios"
import Text from "../Text"
import InstitutionInterface from "../../interfaces/institution.interface"
import Loader from "../Loader"

interface InstitutionPickerInterface {
    onInstitutionSelected?: (institution: InstitutionInterface) => void
}

const InstitutionPicker: React.FC<InstitutionPickerInterface> = ({ onInstitutionSelected = () => { } }) => {
    const [institutions, setInstitutions] = useState<InstitutionInterface[]>([])
    const [isLoading, setIsLoading] = useState<boolean>(true)

    useEffect(() => {
        getAllInstitutions().then((res: AxiosResponse) => {
            setInstitutions(res.data.payload.institutions)
            setIsLoading(false)
        })
    }, [])

    return (
        <div className="flex flex-col gap-2">
            {/* <InputText placeholder="Buscar institución" /> */}
            <Text>Seleccionar institución</Text>
            {isLoading ?
                <Loader />
                :
                institutions.map((institution) => (
                    <button
                        key={institution.id}
                        className="text-left px-4 py-2 bg-white border rounded text-zinc-700"
                        onClick={() => onInstitutionSelected(institution)}
                    >{institution.abbreviation} - {institution.name}</button>
                ))
            }
        </div>
    )
}

export default InstitutionPicker