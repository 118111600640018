import { createSlice } from '@reduxjs/toolkit'
import UserInterface from '../../interfaces/user.interfaces'

const initialState: UserInterface = {

}

export const userSlice = createSlice({
    name: 'user',
    initialState,
    reducers: {
        setUser: (state, { payload }) => {
            state.id = payload.id
            state.username = payload.username
            state.name = payload.name
            state.email = payload.email
            state.profile_picture = payload.profile_picture
            state.institution = payload.institution || null
        },
        resetUser: () => initialState
    }
})
export const { setUser, resetUser } = userSlice.actions
export default userSlice.reducer