import { createSlice } from '@reduxjs/toolkit'
import { FileInterface } from '../../interfaces/file.interfaces'

export interface themesSliceInterface {
    dark?: boolean
    light?: boolean
    automatic?: boolean
}

export interface QueueItem {
    id?: string
    file?: FileInterface
    data?: File
    promise?: Promise<void>
    progress?: number
}

const initialState: QueueItem[] = []

export const uploadQueue = createSlice({
    name: 'uploadQueue',
    initialState,
    reducers: {
        addToQueue: (state, { payload: items }) => {
            return state.concat(items)
        },
        updateItemProgress: (state, { payload: data }: { payload: { id: string, progress: number } }) => {
            const item = state.find((item) => item.id === data.id)
            if (item) {
                item.progress = data.progress
            }
        },
        setFileDataToItem: (state, { payload: data }: { payload: { id: string, file: FileInterface } }) => {
            const item = state.find((item) => item.id === data.id)
            if (item) {
                item.file = data.file
            }
        },
        removeFileToQueue: (state, { payload: data }: { payload: { id: string } }) => {
            return state.filter((item) => item.file?.id !== data.id)
        },
        restartQueue: () => initialState
    }
})
export const { restartQueue, addToQueue, updateItemProgress, setFileDataToItem, removeFileToQueue } = uploadQueue.actions
export default uploadQueue.reducer