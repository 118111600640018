import { FaUniversity } from "react-icons/fa"
import Body from "../../components/Body"
import Button from "../../components/Button"
import Card from "../../components/Card"
import InputText from "../../components/InputText"
import InstitutionPicker from "../../components/InstitutionPicker"
import Text from "../../components/Text"
import { useAppDispatch, useAppSelector } from "../../store/store"
import { useEffect, useState } from "react"
import InstitutionInterface from "../../interfaces/institution.interface"
import { updateUserData } from "../../services/user.services"
import { AxiosResponse } from "axios"
import { toast } from "react-toastify"
import { setUser } from "../../store/features/userSlice"
import { Helmet } from "react-helmet-async"
import config from "../../config"

const ProfilePage = () => {
    const token = useAppSelector(state => state.token)
    const user = useAppSelector(state => state.user)
    const dispatch = useAppDispatch()

    const [isRequesting, setIsRequesting] = useState<boolean>(false)
    const [userData, setUserData] = useState<{ name?: string, username?: string, institution?: InstitutionInterface | null }>({})

    const handleChange: React.ChangeEventHandler<HTMLInputElement> = (event) => setUserData({ ...userData, [event.target.name]: event.target.value })
    const handleSelectInstitution = (institution: InstitutionInterface) => setUserData({ ...userData, institution })

    const handleUpdate = () => {
        setIsRequesting(true)
        updateUserData({
            token: token || '',
            name: userData.name as string,
            username: userData.username,
            institution_id: userData.institution?.id
        })
            .then((res: AxiosResponse) => {
                dispatch(setUser(res.data.payload.user))
                toast.success("¡Perfil actualizado!")
            })
            .catch((error) => {
                toast.error(error.response?.data?.message || 'Algo malió sal :/')
            })
            .finally(() => setIsRequesting(false))
    }

    useEffect(() => {
        setUserData({
            name: user?.name || '',
            username: user?.username || '',
            institution: user.institution || null
        })
    }, [])

    return (
        <>
            <Helmet>
                <title>Mí perfil {config.titleSeparator}</title>
                <meta
                    name="description"
                    content={config.pageDescription}
                />
            </Helmet>
            <Body className="items-center p-5">
                <Card className="p-5 gap-4">
                    <img className="self-center w-[70px] h-[70px] rounded-full" src={user.profile_picture} alt={user.name} />
                    <div className="flex flex-col gap-5">
                        <InputText name="name" placeholder="Nombre" onChange={handleChange} value={userData.name} />
                        <div className="flex flex-col">
                            <InputText name="username" placeholder="Usuario" onChange={handleChange} value={userData.username} />
                            {!userData.username && <Text size="sm">No tienes creado un nombre de usuario. ¡Crealo!</Text>}
                        </div>
                        <div className="flex flex-col">
                            <InputText placeholder="Correo electrónico" value={user.email} readOnly disabled />
                            <Text size="sm">No podés cambiar tu correo electrónico</Text>
                        </div>
                        {userData.institution ?
                            <div className="flex gap-2 items-center">
                                <Text flexed><FaUniversity /> {userData.institution.name}</Text>
                                <button
                                    onClick={() => setUserData({ ...userData, institution: null })}
                                    className="text-blue-500 text-xs uppercase font-semibold">Editar</button>
                            </div>
                            :
                            <InstitutionPicker onInstitutionSelected={handleSelectInstitution} />
                        }
                        <Button onClick={handleUpdate} spinner={isRequesting} disabled={isRequesting}>Actualizar</Button>
                    </div>
                </Card>
            </Body>
        </>
    )
}

export default ProfilePage