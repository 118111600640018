import { createSlice } from '@reduxjs/toolkit'
import InstitutionInterface from '../../interfaces/institution.interface'

const initialState: InstitutionInterface = {

}

export const institutionSlice = createSlice({
    name: 'user',
    initialState,
    reducers: {
        setInstitution: (state, { payload }) => {
            state.id = payload.id
            state.name = payload.name
            state.abbreviation = payload.abbreviation
            state.country = payload.country
            state.website = payload.website
            state.subjects = payload.subjects
        },
        resetInstitution: () => initialState
    }
})
export const { setInstitution, resetInstitution } = institutionSlice.actions
export default institutionSlice.reducer