export interface FilePickerComponentInterface {
    onFileSelected?: (files: File[]) => void
}

const FilePicker: React.FC<FilePickerComponentInterface> = ({ onFileSelected = () => { } }) => {
    const handleOnChange: React.ChangeEventHandler<HTMLInputElement> = (event) => {
        const files = event.target.files || new FileList()

        if (files?.length > 0) {
            onFileSelected(Object.values(files))
        }
    }

    return (
        <label className="
            flex flex-col items-center justify-center
            h-[80px]
            bg-blue-50
            rounded border-2 border-blue-500 border-dashed
            cursor-pointer
        ">
            <p className="text-blue-500 font-semibold text-xl">Seleccionar</p>
            <p className="text-blue-500 text-sm">Archivos aceptados: <span className="font-bold">.pdf, .docx, .doc, .xlsx</span></p>
            <input type="file" onChange={handleOnChange} accept=".pdf,.doc,.docx,.xlsx" multiple hidden />
        </label>
    )
}

export default FilePicker