import { useEffect, useState } from "react"
import Body from "../../components/Body"
import { useAppSelector } from "../../store/store"
import Text from "../../components/Text"
import { useNavigate, useParams } from "react-router-dom"
import { getFileData } from "../../services/file.services"
import { AxiosResponse } from "axios"
import BodyLoading from "../../components/BodyLoading"
import { FileInterface } from "../../interfaces/file.interfaces"
import { RiFolder4Fill } from "react-icons/ri"
import { FaUniversity } from "react-icons/fa"
import InputText from "../../components/InputText"
import InstitutionPicker from "../../components/InstitutionPicker"
import SubjectPicker from "../../components/SubjectPicker"
import DocumentCategoryPicker from "../../components/DocumentCategoryPicker"
import { sizeParse } from "../../utils/files"
import DocumentInterface from "../../interfaces/document.interfaces"
import { createDocument } from "../../services/document.services"
import { toast } from "react-toastify"
import Button from "../../components/Button"
import { Helmet } from "react-helmet-async"
import config from "../../config"


const FileDetails = () => {
    const { fileId } = useParams() as { fileId: string }

    const themes = useAppSelector(state => state.themes)
    const token = useAppSelector(state => state.token)
    const navigate = useNavigate()

    const [isLoading, setIsLoading] = useState<boolean>(true)
    const [isRequesting, setIsRequesting] = useState<boolean>(false)

    const [fileData, setFileData] = useState<FileInterface | null>(null)
    const [documentData, setDocumentData] = useState<DocumentInterface>({
        title: '',
        description: '',
        institution: null,
        subject: null,
        document_category: null,
    })

    const handleChange: React.ChangeEventHandler<HTMLInputElement> = (event) => {
        setDocumentData({ ...documentData, [event.target.name]: event.target.value })
    }

    const handleEditDetails = (data: string) => {
        if (data === 'institution') {
            setDocumentData({ ...documentData, institution: null, document_category: null, subject: null })
        } else if (data === 'subject') {
            setDocumentData({ ...documentData, document_category: null, subject: null })
        } else {
            setDocumentData({ ...documentData, document_category: null })
        }
    }

    const handleCreateDocument = () => {
        setIsRequesting(true)
        createDocument({
            token: token || '',
            title: documentData.title as string,
            description: documentData.description as string,
            document_category_id: documentData.document_category?.id as string,
            subject_id: documentData.subject?.id as string,
            file_id: fileId
        })
            .then((res: AxiosResponse) => {
                toast.success('¡Documento creado!')
                navigate(`/documents/${res.data.payload.document.id}`, { replace: true })
            })
            .catch((error: any) => {
                return toast.error(error.response.data.message || 'Algo malió sal :/')
            })
            .finally(() => setIsRequesting(false))
    }

    useEffect(() => {

        getFileData({ token: token || '', fileId }).then((res: AxiosResponse) => {
            setIsLoading(false)
            setFileData(res.data.payload.file)
        })

    }, [token, fileId])

    if (isLoading) return <BodyLoading />

    if (fileData === null) return (
        <Body className="items-center justify-center">
            <Text size="4xl">Archivo no encontrado</Text>
            <img src="/4x.png" alt="no encontrado" />
        </Body>
    )

    return (
        <>
            <Helmet>
                <title>Finalizar subida {config.titleSeparator}</title>
                <meta
                    name="description"
                    content={config.pageDescription}
                />
            </Helmet>
            <Body className="items-center p-5">
                <div className={`
                flex flex-col

                gap-4

                w-full max-w-[520px]

                ${themes.dark && 'bg-zinc-950'} ${themes.light && 'bg-white'}
                ${themes.automatic && 'bg-white dark:bg-zinc-950'}

                border

                ${themes.dark && 'border-zinc-950'} ${themes.light && 'border-gray-200'} 
                ${themes.automatic && 'border-gray-200 dark:border-zinc-950'}

                rounded-2xl


            `}>
                    <div className="px-6 pt-5">
                        <Text>
                            <span className="font-semibold">{fileData.name}</span>
                        </Text>
                        <Text size="sm">{sizeParse(fileData.size as number)}</Text>
                    </div>
                    <div className="border-t border-b px-5 py-2">
                        <Text size="sm">Previsualización pública del archivo</Text>
                        <div className="flex items-center gap-5">
                            <div className="flex items-center justify-center min-w-[70px] max-w-[70px] min-h-[70px] max-h-[70px] px-5 py-2 rounded-lg bg-blue-100">
                                <p className="text-blue-500 font-semibold">{fileData.extension}</p>
                            </div>
                            <div className="flex flex-col gap-2 overflow-hidden">
                                <Text size="2xl">{documentData.title}</Text>
                                {
                                    (documentData.subject?.name || documentData.institution?.name) && <div className="flex flex-col md:flex-row md:gap-5 gap-1">
                                        {documentData.subject?.name && <Text size="sm" flexed><RiFolder4Fill /> {documentData.subject?.name}</Text>}
                                        {documentData.institution?.name && <Text size="sm" flexed><FaUniversity /> {documentData.institution?.name}</Text>}
                                    </div>
                                }
                                {documentData.description && <Text size="sm" truncate>{documentData.description}</Text>}
                                {documentData.document_category?.name && <button className="px-5 py-2 bg-blue-100 text-blue-500 text-[10px] font-semibold uppercase rounded-full self-start">{documentData.document_category?.name}</button>}
                            </div>
                        </div>
                    </div>
                    <div className="flex flex-col px-5 pb-10 gap-5">
                        <InputText onChange={handleChange} name="title" placeholder="Título" value={documentData.title} />

                        {documentData.institution ?
                            <div className="flex gap-2">
                                <Text flexed><FaUniversity /> {documentData.institution?.name}</Text>
                                <button
                                    onClick={() => handleEditDetails('institution')}
                                    className="text-blue-500 text-xs uppercase font-semibold">Editar</button>
                            </div>
                            :
                            <InstitutionPicker
                                onInstitutionSelected={(institution) => setDocumentData({ ...documentData, institution })}
                            />
                        }

                        {documentData.institution && !documentData.subject &&
                            <SubjectPicker
                                institution_id={documentData.institution.id}
                                onSubjectSelected={(subject) => setDocumentData({ ...documentData, subject })}
                            />
                        }

                        {documentData.institution && documentData.subject &&
                            <div className="flex items-center gap-2">
                                <Text flexed><RiFolder4Fill /> {documentData.subject?.name}</Text>
                                <button
                                    onClick={() => handleEditDetails('subject')}
                                    className="text-blue-500 text-xs uppercase font-semibold">Editar</button>
                            </div>
                        }

                        {documentData.institution && documentData.subject && !documentData.document_category &&
                            <DocumentCategoryPicker
                                onDocumentCategorySelected={(category) => setDocumentData({ ...documentData, document_category: category })}
                            />
                        }
                        {documentData.institution && documentData.subject && documentData.document_category &&
                            <div className="flex gap-2 items-center">
                                <button className="px-5 py-2 bg-blue-100 text-blue-500 text-[10px] font-semibold uppercase rounded-full self-start">
                                    {documentData.document_category?.name}
                                </button>
                                <button
                                    onClick={() => handleEditDetails('category')}
                                    className="text-blue-500 text-xs uppercase font-semibold">Editar</button>
                            </div>
                        }

                        {documentData.institution && documentData.subject && documentData.document_category &&
                            <textarea
                                className="rounded p-2 border resize-none outline-none"
                                name="description"
                                rows={2}
                                onChange={(event) => setDocumentData({ ...documentData, description: event.target.value })}
                                placeholder="Breve descripción"
                                value={documentData.description}
                            >
                            </textarea>
                        }

                        <Button onClick={handleCreateDocument} spinner={isRequesting} disabled={isRequesting}>
                            Completar
                        </Button>
                    </div>
                </div>
            </Body>
        </>
    )
}

export default FileDetails