import { useEffect, useState } from "react"
import { useAppDispatch, useAppSelector } from "../../store/store"
import { sizeParse } from "../../utils/files"
import Text from "../Text"
import { Link } from "react-router-dom"
import { FileInterface } from "../../interfaces/file.interfaces"
import { BiTrash } from "react-icons/bi"
import DeleteFileButton from "../DeleteFileButton"
import { toast } from "react-toastify"
import { removeFileToQueue } from "../../store/features/uploadQueueSlice"

export interface PendingFileComponentInterface {
    id?: string
    data?: File
    file?: FileInterface
    progress?: number
}

const PendingFile: React.FC<PendingFileComponentInterface> = ({ file, data, progress = 0 }) => {
    const themes = useAppSelector(state => state.themes)
    const dispatch = useAppDispatch()
    const [completed, setCompleted] = useState<boolean>(!!file)

    const handleFileDeleted = () => {
        toast.success("¡Archivo eliminado!")
        dispatch(removeFileToQueue({ id: file?.id as string }))
    }

    useEffect(() => {
        if (progress === 100) setCompleted(true)
    }, [progress])

    return (
        <div className="flex flex-col gap-1">
            <div className="flex justify-between overflow-hidden">
                <Text>{file?.name || data?.name}</Text>
                {completed && <DeleteFileButton file={file} onFileDeleted={handleFileDeleted} />}
            </div>
            <div className="flex gap-2 items-center">
                <Text size="sm" className="min-w-fit">
                    {sizeParse(Number(file?.size) || Number(data?.size))}
                </Text>
                {!completed ? <div className={`
                    w-full
                    ${themes.light && 'bg-gray-200'} ${themes.dark && 'bg-gray-700'}
                    ${themes.automatic && 'bg-gray-200 dark:bg-gray-700'}
                    rounded-full
                    overflow-hidden
                `}>
                    <div
                        className="
                            bg-blue-600 
                            text-xs font-medium text-blue-100 text-center
                            p-0.5 
                            leading-none rounded-full 
                            transition-all duration-75
                            "
                        style={{ width: `${progress}%` }}>{progress.toFixed(2)}%</div>
                </div> : <Link to={`/files/${file?.id}/details`} className="text-blue-500">Finalizar subida</Link>}
            </div>
        </div>
    )
}

export default PendingFile