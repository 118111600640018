import { Outlet, useLocation } from "react-router-dom"
import Navigation from "../../components/Navigation"
import Footer from "../../components/Footer"
import { useAppDispatch, useAppSelector } from "../../store/store"
import { useEffect } from "react"
import { getUserData } from "../../services/user.services"
import { setUser } from "../../store/features/userSlice"
import { AxiosResponse } from "axios"
import { setDefaultTheme } from "../../store/features/themesSlice"
import { getPendingFiles } from "../../services/file.services"
import { addToQueue, restartQueue } from "../../store/features/uploadQueueSlice"
import { FileInterface } from "../../interfaces/file.interfaces"
import { toast } from "react-toastify"

const Template = () => {
    const token = useAppSelector(state => state.token)
    const themes = useAppSelector(state => state.themes)
    const location = useLocation()
    const dispatch = useAppDispatch()

    useEffect(() => {
        if (!themes.automatic && !themes.light && themes.dark) {
            dispatch(setDefaultTheme())
        }
    }, [dispatch, themes])

    useEffect(() => {
        if (token) {
            getUserData({ token: token })
                .then((res: AxiosResponse) => {
                    dispatch(setUser(res.data.payload.user))
                })
        }
    }, [token, dispatch])

    useEffect(() => {
        dispatch(restartQueue())
        if (token) {
            getPendingFiles({ token: token || '' })
                .then((res: AxiosResponse) => {
                    dispatch(
                        addToQueue(
                            res.data.payload.files.map((file: FileInterface) => {
                                return {
                                    file
                                }
                            })
                        )
                    )
                })
                .catch(() => toast.error("Algo malió sal :/"))
        }
    }, [token, dispatch, location])

    return (
        <div className="flex flex-col min-h-screen relative">
            <Navigation />
            <Outlet />
            <Footer />
        </div>
    )
}

export default Template