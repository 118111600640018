import { useEffect, useState } from "react"
import Body from "../../components/Body"
import { FileInterface } from "../../interfaces/file.interfaces"
import BodyLoading from "../../components/BodyLoading"
import { getUserFiles } from "../../services/user.services"
import { useAppSelector } from "../../store/store"
import { AxiosResponse } from "axios"
import Document from "../../components/Document"
import { Link } from "react-router-dom"
import Text from "../../components/Text"
import { Helmet } from "react-helmet-async"
import config from "../../config"

const MyLibraryPage = () => {
    const token = useAppSelector(state => state.token)

    const [files, setFiles] = useState<FileInterface[]>([])
    const [isLoading, setIsLoading] = useState<boolean>(true)
    useEffect(() => {
        getUserFiles({ token: token || '' })
            .then((res: AxiosResponse) => {
                setIsLoading(false)
                setFiles(res.data.payload.files)
            })
    }, [])

    if (isLoading) return <BodyLoading />

    return (
        <>
            <Helmet>
                <title>Biblioteca {config.titleSeparator}</title>
                <meta
                    name="description"
                    content={config.pageDescription}
                />
            </Helmet>
            <Body className="p-5 items-center">
                <div className="flex flex-col gap-5 w-full max-w-[520px]">
                    {files.length > 0 ?
                        files.map((file) => {
                            if (file.document) {
                                file.document.file = file
                                return <Document key={file.id} {...file.document} />
                            }
                            return (
                                <Link key={file.id} to={`/files/${file.id}/details`}>
                                    <div className="flex flex-col gap-2 p-5 rounded-lg bg-blue-50 border border-blue-100 overflow-hidden">
                                        <p className="text-blue-500 text-2xl">Seguir subiendo</p>
                                        <p className="text-blue-500 truncate">{file.name}</p>
                                    </div>
                                </Link>
                            )
                        })
                        : <Text>No has subido documentos aún</Text>
                    }
                </div>
            </Body>
        </>
    )
}

export default MyLibraryPage