import { useAppSelector } from "../../store/store"

const InputText: React.FC<React.InputHTMLAttributes<HTMLInputElement>> = (props) => {
    const themes = useAppSelector(state => state.themes)
    return (
        <input
            {...props}
            className={`
                h-[45px]
                px-3

                ${themes.light && 'disabled:bg-gray-100 disabled:text-zinc-700'} ${themes.dark && 'disabled:bg-black disabled:text-white'}
                ${themes.automatic && 'disabled:bg-gray-100 disabled:text-zinc-700 dark:disabled:bg-black dark:disabled:text-white'}
                
                border outline-none rounded
            `.concat(props.className || '')}

        />
    )
}

export default InputText