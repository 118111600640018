import { RiFolder4Fill } from "react-icons/ri"
import Text from "../Text"
import { FaUniversity } from "react-icons/fa"
import DocumentInterface from "../../interfaces/document.interfaces"
import { Link } from "react-router-dom"

const Document: React.FC<DocumentInterface> = ({ id, title, subject, document_category, file, description }) => {
    return (
        <div className="flex gap-5">
            <div className="flex items-center justify-center min-w-[70px] max-w-[70px] min-h-[70px] max-h-[70px] px-5 py-2 rounded-lg bg-blue-100">
                <p className="text-blue-500 font-semibold">{file?.extension}</p>
            </div>
            <div className="flex flex-col gap-2 overflow-hidden">
                <Link to={`/documents/${id}`}>
                    <Text size="2xl" className="hover:underline">{title}</Text>
                </Link>
                <div className="flex flex-col md:flex-row md:gap-5 gap-1">
                    <div className="flex gap-2 items-center">
                        <RiFolder4Fill className="text-blue-500" />
                        <Text size="sm" className="truncate">
                            {subject?.name}</Text>
                    </div>
                    <div className="flex gap-2 items-center">
                        <FaUniversity className="text-blue-500" />
                        <Text size="sm" className="truncate">
                            {subject?.institution?.name}
                        </Text>
                    </div>
                </div>
                <Text size="sm" truncate>{description}</Text>
                <button className="px-5 py-2 bg-blue-100 text-blue-500 text-[10px] font-semibold uppercase rounded-full self-start">{document_category?.name}</button>
            </div>
        </div>
    )
}

export default Document