import { useEffect, useState } from "react"
import { AxiosResponse } from "axios"
import Text from "../Text"
import DocumentCategoryInterface from "../../interfaces/documentCategory.interfaces"
import { getDocumentCategories } from "../../services/documentCategory.services"
import Loader from "../Loader"

interface DocumentCategoryPickerInterface {
    onDocumentCategorySelected?: (documentCategory: DocumentCategoryInterface) => void
}

const DocumentCategoryPicker: React.FC<DocumentCategoryPickerInterface> = ({ onDocumentCategorySelected = () => { } }) => {

    const [documentCategories, setDocumentCategories] = useState<DocumentCategoryInterface[]>([])
    const [isLoading, setIsLoading] = useState<boolean>(true)

    useEffect(() => {
        getDocumentCategories().then((res: AxiosResponse) => {
            setDocumentCategories(res.data.payload.documentCategories)
            setIsLoading(false)
        })
    }, [])

    return (
        <div className="flex flex-col gap-2">
            <Text>Seleccionar categoría</Text>
            {isLoading ?
                <Loader />
                :
                documentCategories.map((documentCategory) => (
                    <button
                        key={documentCategory.id}
                        className="text-left px-4 py-2 bg-gray-50 border rounded text-zinc-700"
                        onClick={() => onDocumentCategorySelected(documentCategory)}
                    >{documentCategory.name}</button>
                ))
            }
        </div>
    )
}

export default DocumentCategoryPicker