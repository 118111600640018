import Body from "../../components/Body"
import Text from "../../components/Text"
import { useNavigate, useSearchParams } from "react-router-dom"
import { useEffect, useState } from "react"
import { searchDocuments } from "../../services/document.services"
import { AxiosResponse } from "axios"
import DocumentInterface from "../../interfaces/document.interfaces"
import Document from "../../components/Document"
import Button from "../../components/Button"
import BodyLoading from "../../components/BodyLoading"
import { Helmet } from "react-helmet-async"
import config from "../../config"
import SearchFilter, { FilterInterface } from "../../components/SearchFilter"
import { getInstitution } from "../../services/institution.services"
import InstitutionInterface from "../../interfaces/institution.interface"

const searchTypes: Record<string, string> = {
    'documents': 'documents',
    'institutions': 'institutions'
}

const SearchPage = () => {
    const [params, setParams] = useSearchParams()

    const [institution, setInstitution] = useState<InstitutionInterface | null>(null)

    const [filter, setFilter] = useState<FilterInterface>({
        type: searchTypes[params.get("type") as string] || "documents",
        institution: params.get("institution") || null,
        subject: params.get("subject") || null,
        extensions: params.get("extensions") || "*",
        order: "DESC"
    })
    const [searchQuery, setSearchQuery] = useState<string>(params.get('query') || '')
    const [results, setResults] = useState<DocumentInterface[]>([])
    const [isLoading, setIsLoading] = useState<boolean>(true)
    const [isLoadingMore, setIsLoadingMore] = useState<boolean>(false)

    const [paginationData, setPaginationData] = useState<{ total: number, pages: number, currentPage: number }>({
        total: 0,
        pages: 0,
        currentPage: 0,
    })
    const [sortData] = useState<{ sortRef: string, sortOrder: string }>({
        sortRef: 'date',
        sortOrder: 'desc'
    })

    const navigate = useNavigate()

    const handleFilter = (filter: FilterInterface) => {
        const main = `?query=${searchQuery}`
        const values = Object.values(filter)
        const query = Object.keys(filter).map((key, index) => `${key}=${values[index]}`)

        navigate(main + "&" + query.join('&'), { replace: true })
    }

    const loadMore = () => {
        setIsLoadingMore(true)
        searchDocuments({
            // Search props
            institutionId: filter.institution,
            subjectId: filter.subject,
            extensions: filter.extensions,
            query: params.get('query') as string,

            // Pagination props
            limit: 10,
            page: paginationData.currentPage + 1,
            sortOrder: filter.order || 'desc',
            sortRef: sortData.sortRef
        })
            .then((res: AxiosResponse) => {
                setPaginationData({
                    ...paginationData,
                    total: Number(res.data.total),
                    pages: Number(res.data.pages),
                    currentPage: Number(res.data.currentPage)
                })
                setResults(results.concat(res.data.payload.documents))
                setIsLoadingMore(false)
            })
    }

    const search: React.FormEventHandler<HTMLFormElement> = (event) => {
        event?.preventDefault()
        if (searchQuery.trim().length > 0) {
            setPaginationData({
                total: 0,
                pages: 0,
                currentPage: 0,
            })
            const newQueryParameters: URLSearchParams = new URLSearchParams();

            newQueryParameters.set('query', searchQuery)
            setParams(newQueryParameters)
        }
    }

    useEffect(() => {
        setIsLoading(true)

        if (filter.institution) {
            getInstitution({ institutionId: filter.institution }).then((res) => setInstitution(res.data.payload.institution))
        }

        searchDocuments({
            // Search props
            institutionId: filter.institution,
            subjectId: filter.subject,
            extensions: filter.extensions,
            query: params.get('query') as string,

            // Pagination props
            limit: 10,
            page: paginationData.currentPage,
            sortOrder: filter.order || 'desc',
            sortRef: sortData.sortRef
        })
            .then((res: AxiosResponse) => {
                setPaginationData({
                    ...paginationData,
                    total: Number(res.data.total),
                    pages: Number(res.data.pages),
                    currentPage: Number(res.data.currentPage)
                })
                setResults(res.data.payload.documents || [])
                setIsLoading(false)
            })
    }, [setResults, params])

    if (isLoading) return <BodyLoading />

    return (
        <>
            <Helmet>
                <title>{params.get('query')} | Búsqueda {config.pageName}</title>
                <meta
                    name="description"
                    content={config.pageDescription}
                />
            </Helmet>
            <Body className="py-5 px-2 md:px-10 items-center">
                <div className="flex flex-col gap-5 max-w-[720px] w-full">
                    <div className="col-span-4 flex flex-col gap-5">
                        <form className="flex flex-col" onSubmit={search}>
                            <input
                                className="w-full text-zinc-700 h-[45px] border rounded-full px-5 outline-none hover:rounded focus:rounded focus:shadow-lg transition-all duration-100"
                                type="search"
                                placeholder={institution ? institution.name : "Buscar"}
                                onChange={(event) => setSearchQuery(event.target.value)}
                                value={searchQuery}
                            />
                        </form>
                        <div className="flex justify-between">
                            <div className="flex items-center gap-10">
                                <button className={`h-[40px] text-white font-semibold border-b-4 ${filter.type === "documents" ? "border-b-blue-500" : "border-b-transparent"}`}>
                                    Documentos
                                </button>
                                {/* <button onClick={() => setInstitutionSearch()} className={`h-[40px] text-white font-semibold border-b-4 ${filter.type === "institutions" ? "border-b-blue-500" : "border-b-transparent"}`}>
                                    Universidades
                                </button> */}
                                {/* <button className="h-[40px] text-white font-semibold border-b-4 border-b-transparent">
                                    Colecciones
                                </button> */}
                            </div>
                            {/* <SearchFilter onApplied={handleFilter} value={filter} /> */}
                        </div>

                        <Text>{paginationData.total} resultados</Text>

                        <hr></hr>

                        {
                            results.length > 0 ?
                                <>
                                    {results.map((doc) => <Document key={doc.id} {...doc} />)}
                                    {paginationData.currentPage < paginationData.pages &&
                                        <Button onClick={loadMore} spinner={isLoadingMore} disabled={isLoadingMore}>Cargar más</Button>
                                    }
                                </>
                                :
                                <Text>No hubo resultados</Text>
                        }
                    </div>
                </div>
            </Body>
        </>
    )
}

export default SearchPage