import { useEffect, useState } from "react"
import Body from "../../components/Body"
import DocumentInterface from "../../interfaces/document.interfaces"
import { getDocument } from "../../services/document.services"
import { Link, useParams } from "react-router-dom"
import { AxiosResponse } from "axios"
import config from "../../config"
import BodyLoading from "../../components/BodyLoading"
import Text from "../../components/Text"
import { RiFolder4Fill, RiPencilLine } from "react-icons/ri"
import { FaUniversity } from "react-icons/fa"
import DocViewer, { DocViewerRenderers } from "@cyntler/react-doc-viewer"
import { Helmet } from "react-helmet-async"
import TakeNotes from "../../components/TakeNotes"

const DocumentPage = () => {
    const { documentId } = useParams()
    const [document, setDocument] = useState<DocumentInterface | null>()
    const [isLoading, setIsLoading] = useState<boolean>(true)
    const [docSidebarSection, setDocSidebarSection] = useState("info")

    useEffect(() => {
        getDocument({ documentId: documentId as string }).then((res: AxiosResponse) => {
            setDocument(res.data.payload.document)
            setIsLoading(false)
        })
    }, [setDocument, documentId])

    if (isLoading) return (
        <>
            <Helmet>
                <title>Cargando... {config.titleSeparator}</title>
                <meta
                    name="description"
                    content={config.pageDescription}
                />
            </Helmet>
            <BodyLoading />
        </>
    )

    if (document === null) return (
        <>
            <Helmet>
                <title>Archivo no encontrado {config.titleSeparator}</title>
                <meta
                    name="description"
                    content="Lo sentimos pero al parecer esta url es inválida."
                />
            </Helmet>
            <Body className="items-center justify-center">
                <Text size="4xl">Documento no encontrado</Text>
                <img src="/4x.png" alt="no encontrado" />
            </Body>
        </>
    )

    return (
        <>
            <Helmet>
                <title>{document?.title} {config.titleSeparator}</title>
                <meta
                    name="description"
                    content={document?.description || config.pageDescription}
                />
            </Helmet>
            <Body className="grid grid-cols-1 md:grid-cols-6">
                <div className="md:col-span-2 flex flex-col p-5 md:px-10 gap-5">
                    <div className="flex items-center justify-between">
                        <button onClick={() => setDocSidebarSection("info")}>
                            <Text size="3xl">Información</Text>
                        </button>
                        <button onClick={() => setDocSidebarSection("take-notes")}>
                            <Text flexed><RiPencilLine /> Tomar apunte</Text>
                        </button>
                    </div>
                    {docSidebarSection === 'info' ?
                        <div className="flex flex-col gap-5">
                            <Text size="2xl" className="font-semibold">{document?.title}</Text>
                            <Text size="lg">{document?.description}</Text>
                            <div className="flex flex-col gap-1">
                                <Text size="xl" flexed><RiFolder4Fill /> Asignatura</Text>
                                <Link to={`/institutions/${document?.subject?.institution?.url_name || document?.subject?.institution?.id}/${document?.subject?.url_name || document?.subject?.id}`}><Text>{document?.subject?.name}</Text></Link>
                                <Text size="xl" flexed><FaUniversity /> Institución</Text>
                                <Link to={`/institutions/${document?.subject?.institution?.url_name || document?.subject?.institution?.id}`}><Text>{document?.subject?.institution?.name}</Text></Link>
                            </div>
                            <Text className="font-semibold">Subido por:</Text>
                            <div className="flex self-start gap-2 items-center bg-blue-50 px-5 py-2 rounded-3xl">
                                <img className="w-[40px] h-[40px] rounded-full" src={document?.file?.user?.profile_picture} alt={document?.file?.user?.name} />
                                <p className="text-lg font-semibold text-blue-500">{document?.file?.user?.name}</p>
                            </div>
                            <a
                                className="flex items-center justify-center bg-blue-500 text-white text-sm uppercase rounded-full px-5 h-[45px]"
                                href={`${config.API_URL}/files/${document?.file?.id}/view`}
                                download={"view.pdf"}
                            >Descargar</a>
                        </div>
                        : <TakeNotes />}
                </div>
                <div className="flex flex-col min-h-[420px] md:flex-auto md:col-span-4">
                    {
                        document?.file?.extension === '.pdf' ?
                            <embed
                                className="flex-auto"
                                // src={document?.file?.extension === '.docx' ? "https://docs.google.com/viewer?url=https://calibre-ebook.com/downloads/demos/demo.docx&embedded=true" : `${config.API_URL}/files/${document?.file?.id}/view`}
                                // src={`https://docs.google.com/viewer?url=${document?.file?.extension === '.docx' ? "https://calibre-ebook.com/downloads/demos/demo.docx" : `${config.API_URL}/files/${document?.file?.id}/view`}&embedded=true`}
                                src={`${config.API_URL}/files/${document?.file?.id}/view`}
                            ></embed>
                            :
                            <DocViewer documents={[{ uri: `${config.API_URL}/files/${document?.file?.id}/view` }]} pluginRenderers={DocViewerRenderers} />
                    }
                </div>
            </Body>
        </>
    )
}

export default DocumentPage