import { Link } from "react-router-dom"
import SubjectInterface from "../../interfaces/subject.interfaces"
import { RiFolder4Fill } from "react-icons/ri"
import { useAppSelector } from "../../store/store"

const Subject: React.FC<SubjectInterface> = ({ id, name, url_name, institution }) => {
    const themes = useAppSelector(state => state.themes)

    return (
        <Link
            to={`/institutions/${institution?.url_name || institution?.id}/${url_name || id}`}
        >
            <div
                className={`
                    flex items-center gap-2
                    px-5 py-3
                    ${themes.light && 'bg-white hover:bg-gray-100'} ${themes.dark && 'bg-transparent hover:bg-zinc-900'}
                    ${themes.automatic && 'bg-white hover:bg-gray-100 dark:bg-transparent dark:hover:bg-zinc-900'}

                    ${themes.light && 'text-zinc-700'} ${themes.dark && 'text-white'}
                    ${themes.automatic && 'text-zinc-700 dark:text-white '}

                    overflow-hidden
                    rounded
                `}
            >
                <RiFolder4Fill className="text-3xl min-w-[25px] text-blue-500 truncate" />
                <p className="text-lg truncate">{name}</p>
            </div>
        </Link>
    )
}

export default Subject