import { ReactNode } from "react"
import { useAppSelector } from "../../store/store"

interface BodyComponentInterface {
    children?: ReactNode | ReactNode[]
    className?: string
}

const Body: React.FC<BodyComponentInterface> = ({ children, className = '' }) => {
    const themes = useAppSelector(state => state.themes)
    return (
        <div className={
            `
            ${themes.light && 'bg-white'} ${themes.dark && 'bg-zinc-800'}
            ${themes.automatic && 'bg-white dark:bg-zinc-800'}
            flex flex-col flex-auto
            `.concat(className)
        }>
            {children}
        </div>
    )
}

export default Body