import { useState } from "react"
import Body from "../../components/Body"
import { useAppDispatch, useAppSelector } from "../../store/store"
import Text from "../../components/Text"
import FilePicker from "../../components/FilePicker"
import axios, { AxiosResponse } from "axios"
import config from "../../config"
import { Helmet } from "react-helmet-async"
import PendingFile from "../../components/PendingFile"
import { v4 as uuid } from 'uuid'
import { addToQueue, setFileDataToItem, updateItemProgress } from "../../store/features/uploadQueueSlice"

const UploadPage = () => {
    const themes = useAppSelector(state => state.themes)
    const token = useAppSelector(state => state.token)
    const queue = useAppSelector(state => state.uploadQueue)
    const dispatch = useAppDispatch()

    const [isLoading, setIsLoading] = useState<boolean>(true)

    const uploadFile = (id: string, data: File): Promise<void> => {
        return axios.postForm(`${config.API_URL}/files`, {
            file: data
        }, {
            headers: {
                Authorization: `Bearer ${token}`
            },
            onUploadProgress(progressEvent) {
                dispatch(updateItemProgress({ id, progress: (progressEvent.progress || 0) * 100 }))
            },
        })
            .then((res: AxiosResponse) => {
                dispatch(setFileDataToItem({ id, file: res.data.payload.file }))
            })
    }

    const handleFileSelected = (list: File[]) => {
        const items = list.map((file) => {
            const id = uuid()

            return {
                id,
                data: file,
                promise: uploadFile(id, file),
                progress: 0
            }
        })
        dispatch(addToQueue(items))
    }

    return (
        <>
            <Helmet>
                <title>Subir archivo {config.titleSeparator}</title>
                <meta
                    name="description"
                    content={config.pageDescription}
                />
            </Helmet>
            <Body className="items-center px-5 pb-10">
                <div className={`
                    flex flex-col

                    gap-4

                    w-full max-w-[520px]

                    ${themes.dark && 'bg-zinc-950'} ${themes.light && 'bg-white'}
                    ${themes.automatic && 'bg-white dark:bg-zinc-950'}

                    border

                    ${themes.dark && 'border-zinc-950'} ${themes.light && 'border-gray-200'} 
                    ${themes.automatic && 'border-gray-200 dark:border-zinc-950'}

                    p-10

                    rounded-2xl


                `}>
                    <Text size="xl">Subí tu archivo</Text>
                    <FilePicker onFileSelected={handleFileSelected} />

                    {queue.map((file, index) => <PendingFile key={index} {...file} />)}
                </div>
            </Body>
        </>
    )
}

export default UploadPage