import { createSlice } from '@reduxjs/toolkit'

const initialState: string | null = null

export const userSlice = createSlice({
    name: 'token',
    initialState,
    reducers: {
        setToken: (_, { payload }) => payload,
        deleteToken: () => initialState
    }
})
export const { setToken, deleteToken } = userSlice.actions
export default userSlice.reducer