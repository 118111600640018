import { useEffect, useState } from "react"
import Body from "../../components/Body"
import { Outlet, useParams } from "react-router-dom"
import { AxiosResponse } from "axios"
import BodyLoading from "../../components/BodyLoading"
import Text from "../../components/Text"
import { getInstitution } from "../../services/institution.services"
import { RiLink } from "react-icons/ri"
import { useAppDispatch, useAppSelector } from "../../store/store"
import { setInstitution } from "../../store/features/institutionSlice"
import { Helmet } from "react-helmet-async"
import config from "../../config"

const InstitutionTemplate
    = () => {
        const { institutionId } = useParams()
        const institution = useAppSelector(state => state.institution)
        const dispatch = useAppDispatch()
        const [isLoading, setIsLoading] = useState<boolean>(true)

        useEffect(() => {
            getInstitution({ institutionId: institutionId as string }).then((res: AxiosResponse) => {
                dispatch(setInstitution(res.data.payload.institution))
                setIsLoading(false)
            })
        }, [dispatch, institutionId])

        if (isLoading) return (
            <>
                <Helmet>
                    <title>Cargando... {config.titleSeparator}</title>
                    <meta
                        name="description"
                        content={config.pageDescription}
                    />
                </Helmet>
                <BodyLoading />
            </>
        )


        return (
            <Body className="grid grid-cols-1 md:grid-cols-6">
                <div className="md:col-span-2 flex flex-col px-5 md:px-10 gap-2">
                    <Text size="3xl">Información</Text>
                    <div className="flex flex-col">
                        <Text>Nombre</Text>
                        <Text size="lg" className="font-semibold">{institution?.name}</Text>
                    </div>
                    <div className="flex flex-col">
                        <Text>Abreviación</Text>
                        <Text size="lg" className="font-semibold">{institution?.abbreviation}</Text>
                    </div>
                    <div className="flex flex-col">
                        <Text>Ubicación</Text>
                        <Text size="lg" className="font-semibold">{institution?.country}</Text>
                    </div>
                    <div className="flex flex-col  rounded-3xl">
                        <Text>Sitio web</Text>
                        <a className="flex items-center gap-2 text-blue-500 font-semibold text-lg" href={institution?.website} target="_blank" rel="noopener noreferrer">
                            <RiLink />
                            {institution?.website}
                        </a>
                    </div>
                </div>
                <div className="min-h-[420px] md:flex-auto md:col-span-4 md:p-0 md:pr-10 md:pb-10 p-5">
                    <Outlet />
                </div>
            </Body>
        )
    }

export default InstitutionTemplate
