import axios from "axios";
import config from "../config";



export const getUserData = ({ token }: { token: string }) => axios.get(`${config.API_URL}/users`, {
    headers: {
        Authorization: `Bearer ${token}`
    }
})

export const getUserFiles = ({ token }: { token: string }) => axios.get(`${config.API_URL}/users/files`, {
    headers: {
        Authorization: `Bearer ${token}`
    }
})

export const updateUserData = ({
    token,
    name,
    username,
    institution_id
}: {
    token: string,
    name: string,
    username?: string,
    institution_id?: string
}) => axios.patch(`${config.API_URL}/users`,
    { name, username, institution_id },
    {
        headers: {
            Authorization: `Bearer ${token}`
        }
    }
)