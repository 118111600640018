import { Link } from "react-router-dom"
import { useAppSelector } from "../../store/store"
import FooterSelectTheme from "../FooterSelectTheme"

const Footer = () => {
    const themes = useAppSelector(state => state.themes)
    const user = useAppSelector(state => state.user)
    const token = useAppSelector(state => state.token)

    return (
        <footer className="flex flex-col">
            <div className={
                `
                ${themes.light && 'bg-gray-100'} ${themes.dark && 'bg-zinc-950'}
                ${themes.automatic && 'bg-gray-100 dark:bg-zinc-950'}

                flex items-center justify-center md:justify-start gap-5 px-5 py-3 border-b border-gray-300`
            }>
                {token &&
                    <>
                        {user.institution ?
                            <Link to={`/institutions/${user.institution.url_name||user.institution.id}`}
                                className={`
                        ${themes.light && 'text-zinc-700'} ${themes.dark && 'text-white'}
                        ${themes.automatic && 'text-zinc-700 dark:text-white'}
                    `}>{user.institution.name}</Link>
                            : <Link to="/profile"
                                className={`
                        ${themes.light && 'text-zinc-700'} ${themes.dark && 'text-white'}
                        ${themes.automatic && 'text-zinc-700 dark:text-white'}
                    `}>Seleccioná tu universidad</Link>
                        }
                    </>
                }
                {token &&
                    <p className={`
                        ${themes.light && 'text-zinc-700'} ${themes.dark && 'text-white'}
                        ${themes.automatic && 'text-zinc-700 dark:text-white'}
                    `}>•</p>
                }
                <FooterSelectTheme />
            </div>
            <div className={
                `
                ${themes.light && 'bg-gray-100'} ${themes.dark && 'bg-zinc-950'}
                ${themes.automatic && 'bg-gray-100 dark:bg-zinc-950'}

                flex flex-wrap items-center justify-center md:justify-start gap-5 px-5 py-3`
            }>
                {/* <a className={`
                    ${themes.light && 'text-zinc-700'} ${themes.dark && 'text-white'}
                    ${themes.automatic && 'text-zinc-700 dark:text-white'}
                `} href="#something">¿Cómo contribuir?</a>
                <p className={`
                    ${themes.light && 'text-zinc-700'} ${themes.dark && 'text-white'}
                    ${themes.automatic && 'text-zinc-700 dark:text-white'}
                `}>•</p>
                <a className={`
                    ${themes.light && 'text-zinc-700'} ${themes.dark && 'text-white'}
                    ${themes.automatic && 'text-zinc-700 dark:text-white'}
                `} href="#something">¿De qué trata?</a>
                <p className={`
                    ${themes.light && 'text-zinc-700'} ${themes.dark && 'text-white'}
                    ${themes.automatic && 'text-zinc-700 dark:text-white'}
                `}>•</p>
                <a className={`
                    ${themes.light && 'text-zinc-700'} ${themes.dark && 'text-white'}
                    ${themes.automatic && 'text-zinc-700 dark:text-white'}
                `} href="#something">¿Quiénes somos?</a>
                <p className={`
                    ${themes.light && 'text-zinc-700'} ${themes.dark && 'text-white'}
                    ${themes.automatic && 'text-zinc-700 dark:text-white'}
                `}>•</p>
                <a className={`
                    ${themes.light && 'text-zinc-700'} ${themes.dark && 'text-white'}
                    ${themes.automatic && 'text-zinc-700 dark:text-white'}
                `} href="#something">Sugerencias</a>
                <p className={`
                    ${themes.light && 'text-zinc-700'} ${themes.dark && 'text-white'}
                    ${themes.automatic && 'text-zinc-700 dark:text-white'}
                `}>•</p> */}
                <Link className={`
                    ${themes.light && 'text-zinc-700'} ${themes.dark && 'text-white'}
                    ${themes.automatic && 'text-zinc-700 dark:text-white'}
                `} to="/terms-and-conditions">Términos y condiciones</Link>
                <p className={`
                    ${themes.light && 'text-zinc-700'} ${themes.dark && 'text-white'}
                    ${themes.automatic && 'text-zinc-700 dark:text-white'}
                `}>•</p>
                <Link className={`
                    ${themes.light && 'text-zinc-700'} ${themes.dark && 'text-white'}
                    ${themes.automatic && 'text-zinc-700 dark:text-white'}
                `} to="/privacy-policies">Políticas de privacidad</Link>
                <p className={`
                    ${themes.light && 'text-zinc-700'} ${themes.dark && 'text-white'}
                    ${themes.automatic && 'text-zinc-700 dark:text-white'}
                `}>•</p>
                <a className={`
                    bg-blue-100

                    text-blue-500
                    rounded-full
                    px-3 py-1
                `} href="https://cafecito.app/juan_gauna" target="_blank" rel="noopener noreferrer">Donar</a>
            </div>
        </footer>
    )
}

export default Footer