import { combineReducers } from "@reduxjs/toolkit"
import tokenSlice from "./features/tokenSlice"
import themesSlice from "./features/themesSlice"
import userSlice from "./features/userSlice"
import institutionSlice from "./features/institutionSlice"
import uploadQueueSlice from "./features/uploadQueueSlice"

const reducers = combineReducers({
    token: tokenSlice,
    themes: themesSlice,
    user: userSlice,
    institution: institutionSlice,
    uploadQueue: uploadQueueSlice
})

export default reducers