import { Helmet } from "react-helmet-async"
import Body from "../../components/Body"
import Text from "../../components/Text"
import config from "../../config"

const PrivacyPoliciesPage = () => {
    return (
        <>
            <Helmet>
                <title>Políticas de privacidad {config.titleSeparator}</title>
                <meta
                    name="description"
                    content={config.pageDescription}
                />
            </Helmet>
            <Body className="p-5 gap-5">
                <Text size="3xl">Política de Privacidad</Text>

                <Text>
                    Nosotros, Decairo, nos comprometemos a proteger tu privacidad en línea. Esta política de privacidad describe cómo recopilamos, utilizamos y protegemos la información personal que nos proporcionas a través de nuestra plataforma de contenido colaborativo. Al utilizar nuestros servicios, aceptas las prácticas descritas en esta política de privacidad.
                </Text>

                <Text className="font-semibold">
                    1. Información que Recopilamos
                </Text>

                <Text>
                    1.1. Información de Registro: Cuando te registras en nuestra plataforma, podemos recopilar cierta información personal, como tu nombre, dirección de correo electrónico y contraseña.
                </Text>

                <Text>
                    1.2. Información de Perfil: Puedes elegir proporcionar información adicional en tu perfil, como tu fotografía, biografía y ubicación.
                </Text>

                <Text>
                    1.3. Contenido Generado por el Usuario: Recopilamos el contenido que compartes en nuestra plataforma, incluyendo publicaciones, comentarios y mensajes.
                </Text>

                <Text>
                    1.4. Información de Uso: Podemos recopilar información sobre cómo interactúas con nuestra plataforma, incluyendo tu actividad de navegación, los dispositivos que utilizas para acceder a nuestro sitio web y tu dirección IP.
                </Text>

                <Text className="font-semibold">
                    2. Uso de la Información
                </Text>

                <Text>
                    2.1. Utilizamos la información que recopilamos para proporcionar y mejorar nuestros servicios, responder a tus consultas y comunicarnos contigo sobre tu cuenta.
                </Text>

                <Text>
                    2.2. Podemos utilizar tu información para personalizar tu experiencia en nuestra plataforma y ofrecerte contenido y funciones que creemos que pueden ser de tu interés.
                </Text>

                <Text>
                    2.3. No vendemos, alquilamos ni compartimos tu información personal con terceros con fines comerciales sin tu consentimiento, excepto según se describe en esta política de privacidad.
                </Text>

                <Text className="font-semibold">
                    3. Cookies y Tecnologías Similares
                </Text>

                <Text>
                    3.1. Utilizamos cookies y tecnologías similares para recopilar información sobre tu actividad en línea y mejorar la funcionalidad de nuestra plataforma.
                </Text>

                <Text>
                    3.2. Puedes configurar tu navegador para que rechace todas las cookies o para que te avise cuando se envíe una cookie. Sin embargo, si desactivas las cookies, es posible que algunas partes de nuestra plataforma no funcionen correctamente.
                </Text>

                <Text className="font-semibold">
                    4. Seguridad de la Información
                </Text>

                <Text>
                    4.1. Nos esforzamos por proteger la seguridad de tu información personal mediante el uso de medidas de seguridad técnicas y organizativas.
                </Text>

                <Text>
                    4.2. Sin embargo, ninguna transmisión de datos por Internet o sistema de almacenamiento de datos puede garantizar la seguridad absoluta. Por lo tanto, no podemos garantizar la seguridad de tu información personal.
                </Text>

                <Text className="font-semibold">
                    5. Enlaces a Otros Sitios Web
                </Text>

                <Text>
                    5.1. Nuestra plataforma puede contener enlaces a sitios web de terceros. No somos responsables de las prácticas de privacidad o el contenido de estos sitios web.
                </Text>

                <Text className="font-semibold">
                    6. Cambios en la Política de Privacidad
                </Text>

                <Text>
                    6.1. Nos reservamos el derecho de modificar esta política de privacidad en cualquier momento. Cualquier cambio será efectivo inmediatamente después de su publicación en esta página. Te recomendamos que revises periódicamente esta política de privacidad para estar al tanto de cualquier cambio.
                </Text>

                <Text className="font-semibold">
                    7. Contacto
                </Text>

                <Text>
                    7.1. Si tienes alguna pregunta o inquietud sobre esta política de privacidad, por favor contáctanos a <a className="text-blue-500 hover:underline" href="mailto:contact@decairo.com">contact@decairo.com</a>.
                </Text>

                <Text>
                    Gracias por confiar en nosotros con tu información personal.
                </Text>

                <Text>
                    Última actualización: 22/04/2024
                </Text>
            </Body>
        </>
    )
}

export default PrivacyPoliciesPage