import { useEffect, useState } from "react"
import InputText from "../InputText"
import { AxiosResponse } from "axios"
import { createSubject, searchSubjects } from "../../services/subject.services"
import { useAppSelector } from "../../store/store"
import SubjectInterface from "../../interfaces/subject.interfaces"
import Loader from "../Loader"

interface SubjectPickerInterface {
    institution_id?: string
    onSubjectSelected?: (onSubjectSelected: SubjectInterface) => void
}

const SubjectPicker: React.FC<SubjectPickerInterface> = ({ institution_id, onSubjectSelected = () => { } }) => {
    const token = useAppSelector(state => state.token)
    const [subjectName, setSubjectName] = useState<string>("")
    const [subjects, setSubjects] = useState<SubjectInterface[]>([])

    const [isLoading, setIsLoading] = useState<boolean>(false)

    const handleCreateSubject = () => createSubject({ token: token || '', institution_id: institution_id as string, subjectName })
        .then((res: AxiosResponse) => {
            onSubjectSelected(res.data.payload.subject)
        })

    useEffect(() => {
        if (subjectName.length > 0) {
            setIsLoading(true)
            setSubjects([])
            searchSubjects({ subjectName, institution_id: institution_id as string }).then((res: AxiosResponse) => {
                setSubjects(res.data.payload.subjects || [])
                setIsLoading(false)
            })
        }
    }, [subjectName, institution_id])

    return (
        <div className="flex flex-col gap-2">
            <InputText placeholder="Buscar asignatura" onChange={(event) => setSubjectName(event.target.value)} value={subjectName} />
            {subjectName.length > 0 &&
                <>
                    {isLoading ?
                        <Loader />
                        :
                        <>
                            {subjects.length > 0 ?
                                subjects.map((subject) => (
                                    <button
                                        key={subject.id}
                                        className="text-left px-4 py-2 bg-gray-50 border rounded text-zinc-700"
                                        onClick={() => onSubjectSelected(subject)}
                                    >{subject.name}</button>
                                ))
                                :
                                <div>
                                    <p>No se encontraron asignaturas</p>
                                    <button className="text-blue-500" onClick={handleCreateSubject}>Crear "{subjectName}"</button>
                                </div>
                            }
                        </>
                    }
                </>
            }
        </div>
    )
}

export default SubjectPicker