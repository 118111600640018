import { ReactNode } from "react"
import { useAppSelector } from "../../store/store"

interface TextComponentInterface extends React.HTMLAttributes<HTMLParagraphElement> {
    children?: ReactNode | ReactNode[]
    size?: string
    truncate?: boolean
    flexed?: boolean
}

const Text: React.FC<TextComponentInterface> = ({ children, size = 'md', truncate = false, flexed = false, className = '' }) => {
    const themes = useAppSelector(state => state.themes)
    return (
        <p className={`
            ${flexed && 'flex gap-2 items-center'}
            ${themes.light && 'text-zinc-700'} ${themes.dark && 'text-white'}
            ${themes.automatic && 'text-zinc-700 dark:text-white '}
            ${size === 'sm' ? 'text-sm'
                : size === 'lg' ? 'text-lg'
                    : size === 'xl' ? 'text-xl'
                        : size === '2xl' ? 'text-2xl'
                            : size === '3xl' ? 'text-3xl'
                                : size === '4xl' ? 'text-4xl'
                                    : size === '5xl' ? 'text-5xl'
                                        : size === '6xl' ? 'text-6xl'
                                            : 'text-md'}
            ${truncate && 'truncate'}
        `.concat(className)}>
            {children}
        </p>
    )
}

export default Text