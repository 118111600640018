import axios from "axios";
import config from "../config";

export const getSubjectDocuments = (
    {
        subjectId,
        page,
    }: {
        subjectId: string
        page: number
        limit: number
        sortRef: string
        sortOrder: string
    }
) => axios.get(`${config.API_URL}/subjects/${subjectId}/documents/?page=${page}`)
export const searchSubjects = ({ subjectName, institution_id }: { subjectName: string, institution_id: string }) => axios.post(`${config.API_URL}/subjects/search`, {
    subjectName, institution_id
})

export const createSubject = ({ subjectName, institution_id, token }: { subjectName: string, institution_id: string, token: string }) => axios.post(`${config.API_URL}/subjects`, {
    subjectName, institution_id
}, {
    headers: {
        Authorization: `Bearer ${token}`
    }
})