import { useAppSelector } from "../../store/store"

export interface CardComponentInterface extends React.DetailedHTMLProps<React.HTMLAttributes<HTMLDivElement>, HTMLDivElement> {

}

const Card: React.FC<React.DetailedHTMLProps<React.HTMLAttributes<HTMLDivElement>, HTMLDivElement>> = (props) => {
    const themes = useAppSelector(state => state.themes)
    return (
        <div className={`
            flex flex-col

            w-full max-w-[520px]

            ${themes.dark && 'bg-zinc-950'} ${themes.light && 'bg-white'}
            ${themes.automatic && 'bg-white dark:bg-zinc-950'}

            border

            ${themes.dark && 'border-zinc-950'} ${themes.light && 'border-gray-200'} 
            ${themes.automatic && 'border-gray-200 dark:border-zinc-950'}

            rounded-2xl


        `.concat(props.className || '')}>
            {props.children}
        </div>
    )
}

export default Card