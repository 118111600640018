import { useState } from "react"
import { useAppDispatch, useAppSelector } from "../../store/store"
import Modal from "../Modal"
import { RiMoonClearFill, RiSunFill } from "react-icons/ri"
import { TbSunMoon } from "react-icons/tb";
import { changeTheme } from "../../store/features/themesSlice";

const FooterSelectTheme = () => {
    const themes = useAppSelector(state => state.themes)
    const [menu, setMenu] = useState<boolean>(false)
    const dispatch = useAppDispatch()

    const selectTheme = (theme: string) => {
        dispatch(changeTheme(theme))
    }

    return (
        <>
            <Modal modalOpen={menu} set={setMenu}>
                <div className="flex flex-col gap-5 w-full p-5 max-w-[360px] bg-gray-200 rounded-3xl overflow-hidden">
                    <div className="flex flex-col gap-2">
                        <p className="text-zinc-700 text-sm">Seleccione una opción</p>
                        <div className="flex flex-col gap-1">
                            <button
                                className="flex items-center gap-2 h-[50px] first:rounded-t-3xl last:rounded-b-3xl bg-zinc-950 text-white text-center px-5"
                                onClick={() => selectTheme('dark')}
                            >
                                <RiMoonClearFill /> Oscuro
                            </button>
                            <button
                                className="flex items-center gap-2 h-[50px] first:rounded-t-3xl last:rounded-b-3xl bg-white text-yellow-500 text-center px-5"
                                onClick={() => selectTheme('light')}
                            >
                                <RiSunFill /> Claro

                            </button>
                            <button
                                className="flex items-center gap-2 h-[50px] first:rounded-t-3xl last:rounded-b-3xl bg-white text-zinc-700 text-center px-5"
                                onClick={() => selectTheme('automatic')}
                            >
                                <TbSunMoon /> <div><span className="text-yellow-500">Auto</span><span className="text-zinc-950 font-semibold">mático</span></div>
                            </button>
                        </div>
                    </div>
                </div>
            </Modal>
            <button
                className={`
                ${themes.light && 'text-zinc-700'} ${themes.dark && 'text-white'}
                ${themes.automatic && 'text-zinc-700 dark:text-white'}
            `}
                onClick={() => setMenu(true)}
            >
                Tema: {
                    themes.light ?
                        'Claro'
                        : themes.dark ?
                            'Oscuro'
                            : 'Automático'
                }
            </button>
        </>
    )
}

export default FooterSelectTheme