import { useEffect, useRef } from "react"

export interface ModalInterface {
    modalOpen?: boolean,
    set?: (state: boolean) => void,
    onClose?: () => void,
    children?: React.ReactNode,
    bgColor?: string
}

const Modal: React.FC<ModalInterface> = ({ modalOpen, set = () => { }, onClose, children, bgColor = "bg-[#0000007d]" }) => {
    const modal = useRef<HTMLDivElement>(null)

    useEffect(() => {
        modal.current?.addEventListener('click', (event: MouseEvent | TouchEvent) => {
            if (!modal.current?.children[0]?.contains(event.target as Node)) {
                if (onClose) onClose()
                else set(false)
                document.body.style.overflow = 'auto'
            }
        })
    }, [set, onClose])
    return (
        <div ref={modal} className={`${!modalOpen ? 'invisible' : 'flex'} flex-col z-10 fixed left-0 top-0 w-full h-full ${bgColor} items-center justify-center`}>
            {children}
        </div>
    )
}

export default Modal